<h2 align="center">Complete the payment with Stripe</h2>
<div class="sr-root">
    <div class="sr-main">
        <form id="payment-form">
            <div id="card-element"><!--Stripe.js injects the Card Element--></div>
            <button id="submit">
              <div class="spinner hidden" id="spinner"></div>
              <span id="button-text">Pay now</span>
            </button>
            <p id="card-error" role="alert"></p>
            <p class="result-message hidden">
              Payment succeeded
            </p>
          </form>
        <div class="stripe-logo">
            <img src="assets/powered_by_stripe.svg">
        </div>
    </div>
</div>
