import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    private intent: string;
    private test: boolean;

    constructor(private _authService: AuthService, private _route: ActivatedRoute, private _router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise(res => {
          this._authService.isAuthenticated(next.paramMap.get('hashlink')).subscribe(
            (data) => {
                if (data.status === "success") {
                    this.intent = data.intent;
                    this.test = data.test;
                    res(true);
                } else {
                    this._router.navigate(['/']);
                    res(false);
                }
            },
            (error) => {
                this._router.navigate(['/']);
                res(false);
            }
            );
        });
    }

    getIntent() {
        return this.intent;
    }

    getTest() {
        return this.test;
    }
}
