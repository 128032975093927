import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    private intent: string;
    private configUrl = environment.api_url;

    constructor(private http: HttpClient) { }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(parameter: string): Observable<any> {
      return this.http.post(this.configUrl, { online_url: parameter });
  }
}
