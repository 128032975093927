import { Component, OnInit } from '@angular/core';
import { AuthGuard} from '../guards/auth-guard.service';
import { environment } from '../../environments/environment';

declare var Stripe: any;

@Component({
  selector: 'app-process-payment',
  templateUrl: './process-payment.component.html',
  styleUrls: ['./process-payment.component.css']
})
export class ProcessPaymentComponent implements OnInit {

  constructor(private authService: AuthGuard) { }

  ngOnInit() {
      // Shows a success message when the payment is complete
      var orderComplete = function(paymentIntentId) {
        loading(false);
        document.querySelector(".result-message").textContent = "Your payment was successful, in a short while you will receive an e-mail. If the email with order information does not arrive soon, contact sales@themonetizr.com and provide order information";
        document.querySelector(".result-message").classList.remove("hidden");
        document.querySelector("button").disabled = true;
      };

      // Show the customer the error from Stripe if their card fails to charge
      var showError = function(errorMsgText) {
        loading(false);
        var errorMsg = document.querySelector("#card-error");
        errorMsg.textContent = errorMsgText;
        setTimeout(function() {
          errorMsg.textContent = "";
        }, 4000);
      };

      // Show a spinner on payment submission
      var loading = function(isLoading) {
        if (isLoading) {
          // Disable the button and show a spinner
          document.querySelector("button").disabled = true;
          document.querySelector("#spinner").classList.remove("hidden");
          document.querySelector("#button-text").classList.add("hidden");
        } else {
          document.querySelector("button").disabled = false;
          document.querySelector("#spinner").classList.add("hidden");
          document.querySelector("#button-text").classList.remove("hidden");
        }
      };

      // Calls stripe.confirmCardPayment
      // If the card requires authentication Stripe shows a pop-up modal to
      // prompt the user to enter authentication details without leaving your page.
      var payWithCard = function(stripe, card, clientSecret) {
        loading(true);
        stripe
          .confirmCardPayment(clientSecret, {
            payment_method: {
              card: card
            }
          })
          .then(function(result) {
            if (result.error) {
              // Show error to your customer
              showError(result.error.message);
            } else {
              // The payment has been processed!
              if (result.paymentIntent.status === 'succeeded') {
                  orderComplete(result.paymentIntent.id);
              } else {
                  showError("Something went wrong");
              }
            }
          });
      };

      // Disable the button until we have Stripe set up on the page
      document.querySelector("button").disabled = true;

      var testMode = this.authService.getTest();
      var key = environment.publishable_test_key;

      if (testMode != true) {
          key = environment.publishable_key;
      }

      // Use stripe public keys
      const stripe = Stripe(key);

      // Create `card` element that will watch for updates
      // and display error messages
      const elements = stripe.elements();

      var style = {
        base: {
          color: "#32325d",
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      // Create an instance of the card Element.
      var card = elements.create('card', {style: style});

      // Add an instance of the card Element into the `card-element` <div>.
      card.mount('#card-element');
      
      card.on("change", function (event) {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector("button").disabled = event.empty;
        document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
      });

      var form = document.getElementById('payment-form');
      // This is a value that has to be acquired from api as a result of fully functional checkout process
      // this is either manual input after Monetizr => checkout/updatecheckout/payment

      var clientSecret = this.authService.getIntent();

      form.addEventListener("submit", function(event) {
        event.preventDefault();
        // Complete payment when the submit button is clicked
        payWithCard(stripe, card, clientSecret);
      });
  }
}
