import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { ProcessPaymentComponent } from './process-payment/process-payment.component';
import { AuthGuard } from './guards/auth-guard.service';
import { HttpClientModule } from '@angular/common/http';

const appRoutes: Routes = [
  {
    path: ':hashlink',
    component: ProcessPaymentComponent,
    canActivate: [AuthGuard]
  },
  {
      path: 'error',
      component: PageNotFoundComponent
  },
  {
      path: '**',
      component: PageNotFoundComponent
  },
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ProcessPaymentComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
