// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  publishable_key: "pk_live_CWmQoXocvis3aEFufn7R1CKf",
  publishable_test_key: "pk_test_OS6QyI1IBsFtonsnFk6rh2wb00mSXyblvu",
  api_url: "http://localhost:8001/api/payments/paymentform"
};


